import $ from 'jquery';
import 'modernizr';
import 'fg-loadcss/src/cssrelpreload';
import InitializerEventDispatcher from './utils/InitializerEvent';
import Language from './utils/Language';
import SvgSpriteExporter from './utils/SvgSpriteExporter';
import {confirmBox} from "./components/AlertBox";
import { initCompare } from "./components/Compare";
import { initFavoriteProducts } from "./components/FavouriteProducts";
import initCarousel from "./components/Carousel";
import initLayout from './components/Layout'

const $standardCarousel = $('.js-slider:visible:not(.slick-initialized)');
const $compareCarousel = $('.js-compare-products-slider:visible:not(.slick-initialized)');

if ($standardCarousel.length > 0 || $compareCarousel.length > 0) {
    initCarousel($standardCarousel, $compareCarousel);
}

const botPattern = "(googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
const re = new RegExp(botPattern, 'i');

window.isCrawler = false;
if (re.test(navigator.userAgent)) {
    window.isCrawler = true;
}

if ('dsLayoutUserDataUrl' in window
    && window.dsLayoutUserDataUrl !== '' && !('isPageBuilderCompatible' in window)
    && !window.isCrawler
) {
    fetch(window.dsLayoutUserDataUrl)
        .then(response => {
            return response.json();
        })
        .then(response => {
            if ('request-country-id' in response
                && !window.location.pathname.toLowerCase().startsWith(`/${response['request-country-id']}`)
            ) {
                window.location = `/${response['request-country-id']}${window.location.pathname}`;
            }

            if ('cart' in response && 'ids' in response.cart && 'total' in response.cart) {
                if (response.cart.ids.length > 0) {
                    const cartContainer = document.getElementById('cart-header-label');

                    if (cartContainer) {
                        cartContainer.innerHTML = `
                            <span data-location="/${Language.url}documents/basket" class="main-header-navigation-item-link active">
                                ${SvgSpriteExporter('icon_basket')}
                                <span class="main-header-navigation-item-text">
                                    ${response.cart.total}
                                </span>
                                <span class="main-header-navigation-item-counter">
                                    ${response.cart.ids.length}
                                </span>
                            </span>`;
                    }
                }
            }

            if ('favorite' in response && 'ids' in response.favorite) {
                import(/* webpackChunkName: "favorite-products" */ './components/FavouriteProducts').then(module => {
                    response.favorite.ids.forEach((id) => {
                        module.updateLinks(id, 'add');
                    });
                });
            }

            if ('comparison' in response
                && 'ids' in response.comparison
                && 'url' in response.comparison
            ) {
                import(/* webpackChunkName: "compare" */ './components/Compare').then(module => {
                    module.updateHeaderLabel(response.comparison.ids.length, response.comparison.url);

                    response.comparison.ids.forEach((id) => {
                        module.updateLinks(id);
                    });
                });
            }

            if ('announcements' in response) {
                setTimeout(() => {
                    import(/* webpackChunkName: "announcement" */ './components/Announcement').then(module => {
                        const announcements = new module.default(response.announcements);
                        announcements.showAvailableAnnouncement();
                    });
                }, 5000);
            }

            if ('visited' in response) {
                if (response.visited.length > 0) {
                    const lastVisitedProductsButton = document.getElementById('last-visited-product-button');

                    if (lastVisitedProductsButton) {
                        const button = document.createElement('span');
                        button.classList.add('button');
                        button.classList.add('button--secondary');
                        button.classList.add('button--with-icon');
                        button.addEventListener('click', () => {
                            import(/* webpackChunkName: "modal-box" */ './components/ModalBox').then(module => {
                                module.ModalBox.open('products/products_visited');
                            });
                        });
                        button.title = Language.t('products.last_viewed');
                        button.innerHTML = `${SvgSpriteExporter('icon_history')}
                                <span class="padding-left-1 hidden-xs">
                                    ${Language.t('products.last_viewed')}
                                </span>`

                        lastVisitedProductsButton.replaceWith(button);
                    }
                }
            }

            if ('page-popup' in response) {
                import(/* webpackChunkName: "modal-box" */ './components/ModalBox').then(module => {
                    module.ModalBox.open(response['page-popup'], null, null, response['page-popup-overlay']);
                });
            }

            if ('form-handler-response' in response) {
                import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                    module.default(response['form-handler-response']);
                });
            }

            if (!('user' in response)) {
                const userLogoutLink = document.getElementById('user-logout-link');

                if (userLogoutLink) {
                    userLogoutLink.remove();
                }
            } else {
                const userSignInLink = document.getElementById('user-sign-in-link');

                if (userSignInLink) {
                    userSignInLink.remove();
                }

                if (window.klaviyo) {
                    window.klaviyo.push(['identify', {
                        'email' : response.user.email,
                        'first_name' : response.user.firstname,
                        'last_name' : response.user.lastname,
                    }]);
                }

                if (window.gtag) {
                    window.gtag('set', 'user_data', {
                        'email': response.user.email,
                        'address': {
                            'first_name': response.user.firstname,
                            'last_name': response.user.lastname,
                        }
                    });
                }

                window.dispatchEvent(new CustomEvent('user:logged'));
            }
        });
}

InitializerEventDispatcher.addEventListener('ContentChanged', () => {
    const stickySidebar = document.getElementsByClassName('js-sticky-sidebar-in-modal-box')[0];

    if (stickySidebar) {
        const modalBoxContent = document.getElementsByClassName('modal-box-content')[0];
        const modalBoxHeader = document.getElementsByClassName('modal-box-header')[0];

        import(/* webpackChunkName: "product" */ './components/Product').then(module => {
            module.default(stickySidebar, modalBoxHeader, modalBoxContent);
        });
    }

    const countDownSelectors = document.getElementsByClassName('js-initializing-countdown-timer');

    if (countDownSelectors.length > 0) {
        import(/* webpackChunkName: "countdown" */ './components/Countdown').then(module => {
            module.default(countDownSelectors);
        });
    }

    const createDemoStoreForm = document.getElementById('create-demo-store-application');
    const uninstallAppButtons = document.getElementsByClassName('initializer--app--uninstall');

    if (createDemoStoreForm || uninstallAppButtons) {
        import(/* webpackChunkName: "tenants" */ './components/Tenants').then(module => {
            module.default(createDemoStoreForm, uninstallAppButtons);
        });
    }

    const expandableContainers = document.querySelectorAll('[data-expandable-container]');

    if (expandableContainers.length > 0) {
        import(/* webpackChunkName: "expandable-container" */ './components/ExpandableContainer').then(module => {
            module.default(expandableContainers);
        });
    }

    const galleryElements = document.querySelectorAll('.js-gallery');

    if (galleryElements.length > 0) {
        Array.prototype.forEach.call(galleryElements, (gallery, index) => {
            gallery.setAttribute('data-pswp-uid', index + 1);
            gallery.addEventListener('click', (e) => {
                e.preventDefault();
                import(/* webpackChunkName: "photo-gallery" */ './components/PhotoGallery').then( module => {
                    module.default(e);
                });
            });
            gallery.classList.remove('js-gallery');
        });
    }
}, 300);

initLayout();
initCompare();
initFavoriteProducts();

const stickySidebar = document.getElementsByClassName('js-sticky-sidebar')[0];

if (stickySidebar) {
    const header = document.getElementById('js-main-header');

    import(/* webpackChunkName: "product" */ './components/Product').then(module => {
        module.default(stickySidebar, header, document);
    });
}

if ('dsProductFilter' in window) {
    import(/* webpackChunkName: "product-filter" */ './components/ProductFilter').then(
        module => module.default(window.dsProductFilter)
    );
}

// The code is used when use Page Builder to load the layout.
// Add timeout because browser need few seconds to apply the STYLE.
document.addEventListener('ContentLoaded', () => {
    initLayout();
});

document.addEventListener('ContentChanged', () => {
    InitializerEventDispatcher.dispatchEvent('ContentChanged');
});

document.addEventListener("CountdownChanged", () => {
    const countDownSelectors = document.getElementsByClassName('js-initializing-countdown-timer');

    if (countDownSelectors.length > 0) {
        import(/* webpackChunkName: "countdown" */ './components/Countdown').then(module => {
            module.default(countDownSelectors);
        });
    }
});

$.ajaxPrefilter(opts => {
    const options = opts;

    if ('dsLanguage' in window) {
        if (options.url.indexOf('://') === -1 && options.url.slice(0, 1) !== '/') {
            options.url = `/${window.dsLanguage.url}${options.url}`;
        }
    }
});

const $body = $('body');

$body.on('click', '[data-facebook-login]', (e) => {
    const appId = e.currentTarget.getAttribute('data-facebook-login');

    if (!window.hasOwnProperty('fbAsyncInit')) {
        window.fbAsyncInit = () => {
            FB.init({
                appId,
                cookie : true,
                xfbml : true,
                version : 'v7.0'
            });

            FB.AppEvents.logPageView();
        };

        const fjs = document.getElementsByTagName('script')[0];
        if (document.getElementById('facebook-jssdk')) {
            return;
        }

        const js = document.createElement('script');
        js.id = 'facebook-jssdk';
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }

    setTimeout(() => {
        FB.getLoginStatus(response => {
            if (response.status === 'connected') {
                document.location = `/${Language.url}social/oauth/connect/facebook`;
            } else {
                FB.login(loginResponse => {
                    if (loginResponse.authResponse) {
                        document.location = `/${Language.url}social/oauth/connect/facebook`;
                    }
                }, {scope: 'public_profile,email'});
            }
        });
    }, 1000);

});

$body.on('click', '.do-show-comment-form', (e) => {
    $(e.currentTarget).hide();

    const $form = $('.do-write-comment-form');
    $form.show();

    $('html').animate({scrollTop: $form.offset().top}, 500);
});


$body.on('mouseenter', '.product', (e) => {
    if (window.innerWidth <= window.dsTabletBreakpoint) {
        return;
    }

    const imagesHolder = e.currentTarget.getElementsByClassName('js--swap-images');

    if (!imagesHolder[0]) {
        return;
    }
    
    const children = [].filter.call(imagesHolder[0].children, (el) => {
        return el.tagName === 'IMG';
    });
    
    if (imagesHolder.length > 0 && children.length > 1) {
        Array.prototype.forEach.call(children, (child, index) => {
            if (child.tagName === "IMG") {
                if (child.dataset.srcset && !child.srcset) {
                    child.srcset = child.dataset.srcset;
                }

                child.style.visibility = "visible";
                if (index > 0) {
                    child.style.display = "block";
                }
                imagesHolder[0].children.item(0).style.visibility = "hidden";
            }
        });
    }
});

$body.on('mouseleave', '.product', (e) => {
    if (window.innerWidth <= window.dsTabletBreakpoint) {
        return;
    }

    const imagesHolder = e.currentTarget.getElementsByClassName('js--swap-images');

    if (!imagesHolder[0]) {
        return;
    }
    
    const children = [].filter.call(imagesHolder[0].children, (el) => {
        return el.tagName === 'IMG';
    });
    
    if (imagesHolder.length > 0 && children.length > 1) {
        Array.prototype.forEach.call(children, (child, index) => {
            if (child.tagName === "IMG") {
                if (index === 0) {
                    child.style.visibility = "visible";
                } else {
                    child.style.visibility = "";
                }
            }
        });
    }
});

$body.on('click', '[data-delete-address]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/delivery_address/${e.currentTarget.getAttribute('data-delete-address')}`,
                data: {
                    commit: 'delete'
                },
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('click', '[data-delete-region]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/delivery_region/${e.currentTarget.getAttribute('data-delete-region')}`,
                data: {
                    commit: 'delete'
                },
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('click', '[data-resend-user-invitation]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/company_user_invitation_resend/${e.currentTarget.getAttribute('data-resend-user-invitation')}`,
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('click', '[data-delete-user-invitation]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/company_user_invitation_delete/${e.currentTarget.getAttribute('data-delete-user-invitation')}`,
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('click', '[data-delete-user]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/company_user_delete/${e.currentTarget.getAttribute('data-delete-user')}`,
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('click', '[data-switch-on-permission]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/company_user_permission_set/`,
                data: {
                    permission: e.currentTarget.getAttribute('data-switch-on-permission'),
                    role: e.currentTarget.getAttribute('data-role-id'),
                    state: 1
                },
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('click', '[data-switch-off-permission]', {}, (e) => {
    confirmBox(Language.t('common.confirm_to_continue'), () => {
        $.ajax(
            {
                type: 'post',
                url: `/${Language.url}users/company_user_permission_set/`,
                data: {
                    permission: e.currentTarget.getAttribute('data-switch-off-permission'),
                    role: e.currentTarget.getAttribute('data-role-id'),
                    state: 0
                },
                success(data) {
                    import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            }
        );
    });
});

$body.on('focus', '[data-copy-src]', function() {
    this.previousValue = this.value;
});

$body.on('change', '[data-copy-src]', function() {
    const hasError = this.getAttribute('data-invalid');

    if (hasError) return false;

    const sourceName = this.getAttribute('data-copy-src');
    const destinationFields = document.querySelectorAll('[data-copy-dest]');

    for (let d = 0; d < destinationFields.length; d+=1) {
        const destinationField = destinationFields.item(d);
        const destinationSources = destinationField.getAttribute(
            'data-copy-dest'
        );
        const destinationSourcesArray = destinationSources.split(',');
        let newValue = '';
        let previousValue = '';
        if (destinationSourcesArray.indexOf(sourceName) === -1) {
            continue;
        }

        if (destinationSourcesArray.length === 1) {
            newValue = this.value;
            previousValue = this.previousValue;
        } else {
            const multipleSources = destinationSources
                .replace(/[\[\]]+/g, '')
                .split(',');

            for (let s = 0; s < multipleSources.length; s++) {
                const sourceElement = multipleSources[s];
                const sourceField = document.querySelectorAll(
                    `[data-copy-src="${sourceElement}"]`
                );

                if (sourceField[0].previousValue === undefined) {
                    newValue = '';
                    break;
                } else {
                    if (sourceField[0].previousValue === '') {
                        sourceField[0].previousValue = sourceField[0].value;
                    }

                    previousValue += `${sourceField[0].previousValue} `;
                }

                newValue += `${sourceField[0].value} `;
            }

            previousValue = previousValue.trim();
            newValue = newValue.trim();
        }

        if (destinationField.value === '' || previousValue === destinationField.value
        ) {
            destinationField.value = newValue;

            const event = document.createEvent('Event');
            event.initEvent('input', false, true);
            destinationField.dispatchEvent(event);
        }
    }
});

const ratingStars = document.querySelectorAll('[data-rating]');

Array.prototype.forEach.call(ratingStars, star => {
    const starsHolder = star.parentElement;
    const input = starsHolder.querySelectorAll('input')[0];

    star.addEventListener('click', () => {
        input.value = star.getAttribute('data-rating');

        starsHolder.classList.remove('rating--1', 'rating--2', 'rating--3', 'rating--4', 'rating--5');
        starsHolder.classList.add(`rating--${input.value}`);

        const changeEvent = document.createEvent('Event');
        changeEvent.initEvent('change', false, true);
        input.dispatchEvent(changeEvent);
    });

    input.form.addEventListener('reset', () => {
        starsHolder.classList.remove('rating--1', 'rating--2', 'rating--3', 'rating--4', 'rating--5');
    });
});

document.addEventListener('CheckBoxGroupActivated', event => {
    if (event.target.hasOwnProperty('MasterCheckbox') &&
        event.target.MasterCheckbox.checkboxGroupid === 'confirm-request-group'
    ) {
        document.getElementById(
            'documents-confirm-multiple-button'
        ).style.display = '';
    }
}, true);

document.addEventListener('CheckBoxGroupDeactivated', event => {
    if (event.target.hasOwnProperty('MasterCheckbox') &&
        event.target.MasterCheckbox.checkboxGroupid === 'confirm-request-group'
    ) {
        document.getElementById(
            'documents-confirm-multiple-button'
        ).style.display = 'none';
    }
}, true);

$body.on('change', '.js--payment-method-leasing', {}, function() {
    if (this.checked) {
        import(/* webpackChunkName: "modal-box" */ './components/ModalBox').then(module => {
            module.ModalBox.open('payments/leasing/schemes');
        });
    }
});

$body.on('click', '[data-remove-product-from-leasing]', {}, function() {
    const $this = $(this);

    if ($this.hasClass('element--half-faded')) {
        return false;
    }

    $this.addClass('element--half-faded');

    $.ajax({
        url: `/${Language.url}documents/basket/products/${$this.attr('data-remove-product-from-leasing')}`,
        type: 'post',
        dataType: 'json',
        data: {
            _method: 'DELETE',
            postAction: {
                location: `/${Language.url}payments/leasing/schemes?firstInstallment=${parseFloat(
                    $('#first_installment').val()
                )}`
            }
        },
        success(data) {
            import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                module.default(data);
            });

            $(this).removeClass('element--half-faded');
        },
        error(request) {
            if (request && 'responseJSON' in request) {
                import(/* webpackChunkName: "request-response" */ './utils/RequestResponse').then(module => {
                    module.default(request.responseJSON);
                });
            }
        }
    });
});

const sortProductsSelector = document.getElementById('product_sorter_selector');

if (sortProductsSelector) {
    sortProductsSelector.addEventListener('change', (event) => {
        const { value } = event.currentTarget;
        const sortArray = value.split(new RegExp('_(?=[^_]*$)'));

        const replaceQueryParam = (param, newval, search) => {
            const regex = new RegExp(`([?;&])${param}[^&;]*[;&]?`);
            const query = search.replace(regex, '$1').replace(/&$/, '');

            return (
                (query.length > 2 ? `${query}&` : '?') + (newval ? `${param}=${newval}` : '')
            );
        }

        let str = replaceQueryParam('sortBy', sortArray[0], window.location.search);
        str = replaceQueryParam('sortDir', sortArray[1], str);

        window.location = window.location.pathname + str + window.location.hash;
    });
}

const {userAgent} = window.navigator;

if ('initAnalyze' in window && !(userAgent.includes('Googlebot') && !(userAgent.includes('Chrome-Lighthouse')) )) {
    window.initAnalyze();
}
